<template lang="pug">
  .time-settings-container
    .container-fluid 
      .row.label
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.time_settings.normal_business_hours')"
          required
          :required-label="$t('company_system.shop_settings.shop_form.required')"
        )

      .row
        .business-hours-radio.d-flex.justify-content-start.align-items-center
          AppRadioButton(
            :value="timeSetting.always_open"
            @change="handleAlwaysOpen(true)"
          )
          .label
            span {{ $t('company_system.shop_settings.shop_form.time_settings.open_24_hours') }}

      .row.time-settings-form-field_small
        .business-hours-radio.d-flex.justify-content-start.align-items-center
          AppRadioButton(
            :value="!timeSetting.always_open"
            @change="handleAlwaysOpen(false)"
          )
          .label
            span {{ $t('company_system.shop_settings.shop_form.time_settings.register_specific_business_hours') }}

      .row.time-settings-form-field(v-if="!timeSetting.always_open")
        .specific-business-hours-container.d-flex.flex-column
          .specific-business-hours-header.d-flex.align-items-center
            .day_header
            .regular-holiday_header.d-flex.justify-content-center.align-items-center
              | {{ $t('company_system.shop_settings.shop_form.time_settings.specific_time_table.regular_holiday') }}
            .time_header.d-flex.align-items-center.justify-content-start
              | {{ $t('company_system.shop_settings.shop_form.time_settings.specific_time_table.time') }}
          .specific-business-hours-body
            .specific-business-hours-row.d-flex.align-items-center(v-for="(dayData, index) in $v.timeSetting.business_days.$each.$iter")
              .day.d-flex.justify-content-center.align-items-center
                | {{ $t(`company_system.shop_settings.shop_form.time_settings.days.${dayData.day.$model}`) }}
              .regular-holiday.d-flex.justify-content-center.align-items-center
                AppCheckbox(
                  :value="dayData.day_off.$model"
                  @change="changeBusinessDayDayOff(index, $event)"
                )
              .time.d-flex.align-items-center
                AppSchedule(
                  :disabled="dayData.day_off.$model"
                  :period="buildBusinessDayPeriod(dayData)"
                  @change-start="changeBusinessDayOpenTime(index, $event)"
                  @change-end="changeBusinessDayCloseTime(index, $event)"
                )

      .row.label
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.time_settings.special_business_hours')"
        )

      .row.time-settings-form-field
        .col-md-12.col-lg-10.col-xxl-4
          .special-business-hours
            .special-business-hour.d-flex.align-items-center.justify-content-between(
              v-for="(businessHour, index) in $v.timeSetting.business_hours.$each.$iter"
              :key="businessHour.id.$model || businessHour._addedAt.$model"
            )
              .period
                AppDatepicker(
                  :class="{ invalid: businessHour.start_date.$error || businessHour.end_date.$error }"
                  :value="getBusinessHourPeriod(businessHour.$model)"
                  range
                  @change="changeBusinessHourPeriod(index, $event)"
                )
              .regular-holiday.d-flex.align-items-center
                AppCheckbox(
                  :value="businessHour.day_off.$model"
                  @change="changeBusinessHour(index, 'day_off', $event)"
                )
                .label {{ $t('company_system.shop_settings.shop_form.time_settings.specific_time_table.regular_holiday') }}
              .schedule.d-flex.align-items-center
                AppSchedule(
                  :disabled="businessHour.day_off.$model"
                  :period="buildBusinessDayPeriod(businessHour)"
                  @change-start="changeBusinessHour(index, 'open_time_in_minutes', $event)"
                  @change-end="changeBusinessHour(index, 'close_time_in_minutes', $event)"
                )
              .delete-button
                AppIconButton.action-button(
                  useAppIcon
                  icon="trash"
                  @click="removeBusinessHour(index)"
                )
            AppAddNewButton.add-new-button.m-r-6(
              title="company_system.shop_settings.shop_form.time_settings.add_special_time"
              @click="addBusinessHour"
            )  

      .row.label.time-settings-form-field_small
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.time_settings.reservation_available_time')"
          required
          :required-label="$t('company_system.shop_settings.shop_form.required')"
        )

      .row.time-settings-form-field
        .reservation-available-time 
          .reservation-time-radio.d-flex.align-items-center
            AppRadioButton(
              :value="reservationType === 'until_hour'"
              @change="setReservationType('until_hour')"
            )
            .reservation-time-body.d-flex.align-items-center.justify-content-start
              .reservation-time-input
                AppNumberInput(
                  :class="{ invalid: isReservationOptionInvalid('until_hour', 'until_hour') }"
                  :disabled="reservationType !== 'until_hour'"
                  :value="getReservationOptionValue('until_hour', 'until_hour')"
                  :min="0"
                  @input="changeReservationOption('until_hour', $event)"
                )
              .reservation-time-text {{ $t('company_system.shop_settings.shop_form.time_settings.reservation_until') }}
          .reservation-time-radio.d-flex.align-items-center
            AppRadioButton(
              :value="reservationType === 'days_before'"
              @change="setReservationType('days_before')"
            )
            .reservation-time-body.d-flex.align-items-center.justify-content-start
              .reservation-time-input
                AppNumberInput(
                  :disabled="reservationType !== 'days_before'"
                  :class="{ invalid: isReservationOptionInvalid('days_before', 'days_before') }"
                  :value="getReservationOptionValue('days_before', 'days_before')"
                  :min="0"
                  @input="changeReservationOption('days_before', $event)"
                )
              .reservation-time-text {{ $t('company_system.shop_settings.shop_form.time_settings.reservation_days_before') }}
              .reservation-time-input
                AppNumberInput(
                  :disabled="reservationType !== 'days_before'"
                  :class="{ invalid: isReservationOptionInvalid('days_before', 'until_hour') }"
                  :value="getReservationOptionValue('days_before', 'until_hour')"
                  :min="0"
                  @input="changeReservationOption('until_hour', $event)"
                )
              .reservation-time-text {{ $t('company_system.shop_settings.shop_form.time_settings.reservation_until') }}
          .reservation-time-radio.d-flex.align-items-center
            AppRadioButton(
              :value="reservationType === 'business_days_before'"
              @change="setReservationType('business_days_before')"
            )
            .reservation-time-body.d-flex.align-items-center.justify-content-start
              .reservation-time-input
                AppNumberInput(
                  :disabled="reservationType !== 'business_days_before'"
                  :class="{ invalid: isReservationOptionInvalid('business_days_before', 'days_before') }"
                  :value="getReservationOptionValue('business_days_before', 'days_before')"
                  :min="0"
                  @input="changeReservationOption('days_before', $event)"
                )
              .reservation-time-text {{ $t('company_system.shop_settings.shop_form.time_settings.reservation_business_days_before') }}
              .reservation-time-input
                AppNumberInput(
                  :disabled="reservationType !== 'business_days_before'"
                  :class="{ invalid: isReservationOptionInvalid('business_days_before', 'until_hour') }"
                  :value="getReservationOptionValue('business_days_before', 'until_hour')"
                  :min="0"
                  @input="changeReservationOption('until_hour', $event)"
                )
              .reservation-time-text {{ $t('company_system.shop_settings.shop_form.time_settings.reservation_until') }}
            
      .row.label
        FormFieldLabel(
          :title="$t('company_system.shop_settings.shop_form.time_settings.late_night_rental')"
        )

      .row
        .late-night-rental.d-flex.align-items-center.justify-content-start
          .late-night-rental-label
            FormFieldLabel(
              :title="$t('company_system.shop_settings.shop_form.time_settings.loan_fee')"
            )
          .loan-fee
            AppNumberInput(
              use-delimiter
              name="loaf-fee"
              :max="999999"
              v-model="timeSetting.loan_fee"
              :placeholder="$t('company_system.shop_settings.shop_form.time_settings.loan_fee')"
              aria-describedby="loan-fee"
            )
          .yen {{ $t('general_settings.yen') }}

      .row
        .rental-hours.d-flex.align-items-center.justify-content-start
          .rental-hours-label 
            FormFieldLabel(
              :title="$t('company_system.shop_settings.shop_form.time_settings.rental_hours')"
            )
          AppSchedule(
            :period="buildRentalHoursPeriod(timeSetting)"
            @change-start="changeRentalTimeFrom($event)"
            @change-end="changeRentalTimeTo($event)"
          )
    Footer(
      delete-enabled
      @save="handleSave"
      @delete="$emit('remove-shop')"
    )
</template>

<script>
  //misc
  import { isEqual } from "lodash-es"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withValidations from "@/mixins/withValidations"
  import withScrollTop from "@/mixins/withScrollTop"

  // stores
  import timeSettingModule from "@/config/store/company_system/shops_settings/shops/time_settings"

  const validationsMixin = withValidations(({ required, requiredIf, minValue }) => ({
    timeSetting: {
      business_days: {
        required: requiredIf(function(nested) {
          return !nested.always_open
        }),
        $each: {
          day: { required: false },
          day_off: { required },
          open_time_in_minutes: {
            required: requiredIf(function(nested) {
              return !nested.day_off && !this.$v.timeSetting.$model.always_open
            })
          },
          close_time_in_minutes: {
            required: requiredIf(function(nested) {
              return !nested.day_off && !this.$v.timeSetting.$model.always_open
            })
          }
        }
      },
      business_hours: {
        $each: {
          id: { required: false },
          _addedAt: { required: false },
          day_off: { required },
          start_date: { required },
          end_date: { required },
          open_time_in_minutes: {
            required: requiredIf(function(nested) {
              return !nested.day_off
            })
          },
          close_time_in_minutes: {
            required: requiredIf(function(nested) {
              return !nested.day_off
            })
          }
        }
      },
      reservation_options: {
        until_hour: { required, minValue: minValue(1) },
        days_before: {
          required: requiredIf(function() {
            return this.$v.timeSetting.$model.reservation_type !== "until_hour"
          }),
          minValue: function(value) {
            return value > 0 || this.$v.timeSetting.$model.reservation_type === "until_hour"
          }
        }
      }
    }
  }))

  const companiesShopTimeSettingMixin = withStoreModule(timeSettingModule, {
    resetState: true,
    name: "companiesShopTimeSettingMixin",
    readers: {
      timeSetting: "item",
      initialItem: "initialItem"
    },
    actions: {
      fetchTimeSetting: "FETCH_ITEM",
      updateTimeSetting: "UPDATE_ITEM"
    },
    mutations: {
      setDirty: "SET_DIRTY",
      resetItem: "RESET_ITEM",
      addBusinessHour: "ADD_BUSINESS_HOUR",
      setBusinessHour: "SET_BUSINESS_HOUR_BY_INDEX",
      removeBusinessHour: "DELETE_BUSINESS_HOUR_BY_INDEX"
    }
  })

  export default {
    components: {
      AppDatepicker: () => import("@/components/elements/AppDatepicker"),
      AppSchedule: () => import("@/components/elements/AppSchedule"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppRadioButton: () => import("@/components/elements/AppRadioButton"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppAddNewButton: () => import("@/components/elements/AppButton/WithIcon/AddNew"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput"),
      Footer: () => import("./Footer")
    },

    mixins: [companiesShopTimeSettingMixin, validationsMixin, withScrollTop],

    props: {
      shopId: {
        type: Number,
        required: false
      },
      active: {
        type: Boolean,
        default: false
      }
    },

    watch: {
      active: {
        handler() {
          if (this.active) {
            this.resetItem()
          } else {
            this.setDirty(false)
          }
        }
      },

      hasUnsavedChanges(useConfirm) {
        this.setDirty(useConfirm)
      }
    },

    mounted() {
      this.fetchTimeSetting(this.shopId)
    },

    methods: {
      handleAlwaysOpen(value) {
        this.timeSetting.always_open = value
      },

      buildBusinessDayPeriod({ open_time_in_minutes, close_time_in_minutes }) {
        return {
          start: {
            value: open_time_in_minutes.$model,
            invalid: open_time_in_minutes.$error
          },
          end: {
            value: close_time_in_minutes.$model,
            invalid: close_time_in_minutes.$error
          }
        }
      },

      buildRentalHoursPeriod({ rental_time_in_minutes_from, rental_time_in_minutes_to }) {
        return {
          start: {
            value: rental_time_in_minutes_from
          },
          end: {
            value: rental_time_in_minutes_to
          }
        }
      },

      changeBusinessDayOpenTime(index, time) {
        const businessDay = this.timeSetting.business_days[index]
        this.timeSetting.business_days.splice(index, 1, { ...businessDay, open_time_in_minutes: time })
      },

      changeBusinessDayCloseTime(index, time) {
        const businessDay = this.timeSetting.business_days[index]
        this.timeSetting.business_days.splice(index, 1, { ...businessDay, close_time_in_minutes: time })
      },

      changeBusinessDayDayOff(index, value) {
        const businessDay = this.timeSetting.business_days[index]
        this.timeSetting.business_days.splice(index, 1, { ...businessDay, day_off: value })
      },

      changeRentalTimeFrom(value) {
        this.timeSetting.rental_time_in_minutes_from = value
      },

      changeRentalTimeTo(value) {
        this.timeSetting.rental_time_in_minutes_to = value
      },

      setReservationType(type) {
        this.timeSetting.reservation_type = type
        this.resetReservationOption(type)
        this.$v.timeSetting.reservation_options.$reset()
      },

      resetReservationOption(type) {
        if (type === "until_hour") {
          this.timeSetting.reservation_options = { until_hour: null }
        } else {
          this.timeSetting.reservation_options = { until_hour: null, days_before: null }
        }
      },

      changeReservationOption(option, value) {
        this.timeSetting.reservation_options[option] = value
      },

      changeBusinessHourPeriod(index, period) {
        const businessHour = this.timeSetting.business_hours[index]
        const newPeriod = { start_date: period[0].toString(), end_date: period[1].toString() }
        this.setBusinessHour({ item: { ...businessHour, ...newPeriod }, index })
      },

      changeBusinessHour(index, field, value) {
        const businessHour = this.timeSetting.business_hours[index]
        this.setBusinessHour({ item: { ...businessHour, [field]: value }, index })
      },

      getReservationOptionValue(type, option) {
        return this.reservationType === type ? this.reservationOptions[option] : undefined
      },

      getBusinessHourPeriod({ start_date, end_date }) {
        return start_date && end_date ? [new Date(start_date), new Date(end_date)] : []
      },

      isReservationOptionInvalid(type, option) {
        return this.reservationType === type && this.$v.timeSetting.reservation_options[option].$error
      },

      handleSave() {
        this.validateAttributes()
        if (!this.isValid) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".invalid", inline: "center" })
          })
          return
        }
        this.sendRequest().then(() => {
          this.cancelValidation()
          this.$emit("refresh-shop")
        })
      },

      async sendRequest() {
        await this.updateTimeSetting({ shopId: this.shopId, time_setting: this.timeSetting })
      }
    },

    computed: {
      hasUnsavedChanges() {
        return !isEqual(this.timeSetting, this.initialItem)
      },

      reservationType() {
        return this.timeSetting.reservation_type
      },

      reservationOptions() {
        return this.timeSetting.reservation_options
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/mixins/common.sass"
  @import "@/assets/styles/mixins/company-system.sass"

  .time-settings-container
    .row
      &.label
        +form-label

      ::v-deep
        .required
          margin-left: 8px
        > div
          padding: 0

    .business-hours-radio

        .label
          margin-left: 8px

    .time-settings-form-field
        margin-top: 5px
        margin-bottom: 24px
        &_small
          margin-top: 4px
          margin-bottom: 8px

    .specific-business-hours-container
      width: 360px
      height: 420px

      .specific-business-hours-header
        height: 36px

      .day
        width: 18%
        background-color: $border-element-color
        height: 100%
        &_header
          width: 18%
          height: 100%

      .regular-holiday
        width: 18%
        height: 100%

        &_header
          width: 18%
          height: 100%
          background-color: $border-element-color

      .time
        width: 64%
        height: 100%

        &_header
          background-color: $border-element-color
          height: 100%
          width: 64%

        ::v-deep
          .schedule-container
            width: 100%

      .specific-business-hours-row
        height: 48px

    .special-business-hour
      .period
        width: 33%

        .invalid
          ::v-deep
            input
              +default-invalid-input

        ::v-deep
          .mx-datepicker-range
            width: 100%
          .mx-input
            border-color: #ccc

      .label
        word-break: keep-all

      .regular-holiday
        ::v-deep
          .app-checkbox
            padding: 0

      .delete-button
        width: 5%
        .action-button
          +icon-button($default-purple)
          border-color: transparent
          width: 100%
          height: 34px


    .add-new-button
      margin-top: 5px


    .reservation-time-radio
      &:not(:last-child)
        margin-bottom: 4px

    .reservation-time-body
      width: 100%

    .reservation-time-input
      margin-left: 4px

      ::v-deep
        .app-number-input
          width: 36px
          height: 38px

          input
            width: 100%
            height: 100%
            font-size: 16px
            border-color: #ced4da
            opacity: 1

            &:focus
              background-color: #f2f3fd
              border-color: #80bdff

          &.invalid
            input
              +default-invalid-input

        &:disabled
          background-color: transparent

        padding: 0
        text-align: center
        font-size: 16px

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0

    .reservation-time-text
      margin-left: 4px

    .late-night-rental
      margin-bottom: 4px
      width: 100%
      &-label
        margin-right: 12px
        width: 15%

      .loan-fee
        margin-right: 4px

        ::v-deep
          .app-number-input
            input
              font-size: 16px
              width: 100px
              height: 38px
              text-align: right
              border-color: #ced4da
              opacity: 1

              &:focus
                background-color: #f2f3fd
                border-color: #80bdff

    .rental-hours
      width: 100%
      &-label
        margin-right: 12px
        width: 15%
</style>
